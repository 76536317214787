html.with-footer {
  position: relative;
  min-height: 100%;
  z-index: $zindex-html;
}

html.with-footer body {
  margin-bottom: ($footer-height + $header-footer-margin);
}

.header {
  height: $header-height;
  margin-bottom: $header-footer-margin;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer > * {
  height: $footer-height;
}
