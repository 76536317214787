@mixin centered-modal-non-modal($header: false, $footer: false) {
  $selector: 'html';
  @if $header {
    $selector: $selector + '.with-header';
  }
  @if $footer {
    $selector: $selector + '.with-footer';
  }

  $margin: 0;
  @if $header {
    $margin: $margin + $header-height + $header-footer-margin;
  }
  @if $footer {
    $margin: $margin + $footer-height + $header-footer-margin;
  }

  #{$selector} {
    .modal-non-modal {
      .modal-dialog-centered {
        min-height: calc(100% - (#{$modal-dialog-margin} * 2) - (#{$margin}));
        &::before {
          height: calc(100vh - (#{$modal-dialog-margin} * 2) - (#{$margin}));
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    #{$selector} {
      .modal-non-modal {
        .modal-dialog-centered {
          min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2) - (#{$margin}));
          &::before {
            height: calc(100vh - (#{$modal-dialog-margin-y-sm-up} * 2) - (#{$margin}));
          }
        }
      }
    }
  }
}

@include centered-modal-non-modal($header: true);
@include centered-modal-non-modal($footer: true);
@include centered-modal-non-modal($header: true, $footer: true);
