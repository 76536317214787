/**
 * For whatever reason, a select2 inside an input group has a flex display.
 * The implies that it does not have its maximum width.
 * This monkey patch aims to make it great again.
 */

.input-group > .select2-container--bootstrap {
  > .selection {
    display: inline;
  }
}
